



function radioShowHideFields($field) {
  $section = $field.closest('.toggleshowcontainer');
  if ($section.length === 0) {
    $section = $field.closest('form');
  }

  console.log($field.data('showtarget'));
  var field_val = $('input[name="'+$field.attr('name')+'"]:checked').val();
  // console.log(field_val);
  // console.log($field.val());
  $target = $section.find($field.data('showtarget'));

  if ($field.data('showif') && (String(field_val) === String($field.data('showif')))) {
    // console.log('show!');
    // console.log($target);
    $target.show();
  } else {
    // console.log('hide!');
    // console.log($target);
    $target.hide();
  }
}

function radioHideShowFields($field) {
  $section = $field.closest('.toggleshowcontainer');
  if ($section.length === 0) {
    $section = $field.closest('form');
  }

  var field_val = $('input[name="'+$field.attr('name')+'"]:checked').val();
  // console.log($field.data('hidetarget'));
  // console.log(field_val);
  $target = $section.find($field.data('hidetarget'));

  if ($field.data('hideif') && (String(field_val) === String($field.data('hideif')))) {
    // console.log('hide!');
    // console.log($target);
    $target.hide();
  } else {
    // console.log('show!');
    // console.log($target);
    $target.show();
  }
}

function otherCheckboxShowHideFields($other_checkbox, show_hide_section) {
  $section = $other_checkbox.closest('.toggleshowcontainer');
  if ($section.length === 0) {
    $section = $other_checkbox.closest('form');
  }

  var field_checked = $other_checkbox.is(':checked');
  console.log('field_checked', field_checked);
  $target = $section.find(show_hide_section);

  if (field_checked) {
    console.log('show!');
    console.log($target);
    $target.show();
  } else {
    console.log('hide!');
    console.log($target);
    $target.hide();
  }
}


$(document).on('change', 'input[data-behaviour~=toggleshow][type=radio]', function(evt) {
  radioShowHideFields($(evt.target));
});
$(document).on('change', 'input[data-behaviour~=togglehide][type=radio]', function(evt) {
  radioHideShowFields($(evt.target));
});

$(document).on('change', 'input#accident_report_injury_type_other[type=checkbox]', function(evt) {
  otherCheckboxShowHideFields($(evt.target), '.injury_other_row');
});

$(document).on('turbolinks:load', function() {
  $('input[data-behaviour~=toggleshow][type=radio]').each(function(idx) {
    radioShowHideFields($(this));
  });
  $('input[data-behaviour~=togglehide][type=radio]').each(function(idx) {
    radioHideShowFields($(this));
  });
  $('input#accident_report_injury_type_other[type=checkbox]').each(function(idx) {
    otherCheckboxShowHideFields($(this), '.injury_other_row');
  });
})

$(document).on('sprinkles:update', function(event) {
  $('input[data-behaviour~=toggleshow][type=radio]').each(function(idx) {
    radioShowHideFields($(this));
  });
  $('input[data-behaviour~=togglehide][type=radio]').each(function(idx) {
    radioHideShowFields($(this));
  });
})


$(document).on('turbolinks:load', function() {
  $('input[data-behaviour~=toggleshow][type=radio]').each(function(idx) {
    radioShowHideFields($(this));
  });
  $('input[data-behaviour~=togglehide][type=radio]').each(function(idx) {
    radioHideShowFields($(this));
  });

  $('.business_type_other_row').hide();
  $('input#user_current_team_attributes_business_type_other').on('change',function() {
    console.log('changed');
    console.log($('input#user_current_team_attributes_business_type_other').is(':checked'));
    if ($('input#user_current_team_attributes_business_type_other').is(':checked')) {
      $('.business_type_other_row').show();
    } else {
      $('.business_type_other_row').hide();
    }
  });
  $('input#team_business_type_other').on('change',function() {
    console.log('changed');
    console.log($('input#team_business_type_other').is(':checked'));
    if ($('input#team_business_type_other').is(':checked')) {
      $('.business_type_other_row').show();
    } else {
      $('.business_type_other_row').hide();
    }
  });

  $('.company_type_other_row').hide();
  $('.company_type_row input').on('change',function() {
    console.log('changed');
    console.log($('input#user_current_team_attributes_company_type_other').is(':checked'));
    if ($('input#user_current_team_attributes_company_type_other').is(':checked')) {
      $('.company_type_other_row').show();
    } else {
      $('.company_type_other_row').hide();
    }
  });
  // $('input#team_company_type_other').on('change',function() {
  //   console.log('changed');
  //   console.log($('input#team_company_type_other').is(':checked'));
  //   if ($('input#team_company_type_other').is(':checked')) {
  //     $('.company_type_other_row').show();
  //   } else {
  //     $('.company_type_other_row').hide();
  //   }
  // });

})

$(document).on('change', 'select[data-behaviour~=categoryselector]', function(evt) {
  console.log('changed');
  path = location.pathname + '?category_id=' + $(this).val();
  console.log(path);
  Turbolinks.visit(path)
});


$(document).on('click', 'button[data-behaviour~=monthlypricing]', function(evt) {
  evt.preventDefault();
  console.log('clicked');
  $('.pricing-plan.year').hide();
  $('.pricing-plan.month').show();
});

$(document).on('click', 'button[data-behaviour~=yearlypricing]', function(evt) {
  evt.preventDefault();
  console.log('clicked');
  $('.pricing-plan.year').show();
  $('.pricing-plan.month').hide();
});

$(document).on('turbolinks:load', function() {
  $('.pricing-plan.year').hide();
  $('.pricing-plan.month').show();
  $(this).addClass('active');
})
$(document).on('turbolinks:load', function() {
  $('.pricing-btn-group').on('click', '.btn', function() {
    $(this).addClass('active').siblings().removeClass('active');
  });
})
