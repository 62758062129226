import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';

$(document).on("turbolinks:load", function () {

  const calendarEle = document.getElementById("blockedDaysCalendar");
  if (calendarEle == undefined) return;

  window.teamLeavesCalendar = new Calendar(calendarEle, {
    plugins: [dayGridPlugin, interactionPlugin, bootstrapPlugin],
    schedulerLicenseKey: "0616196517-fcs-1661861808",
    initialView: 'dayGridMonth',
    themeSystem: 'bootstrap',
    locale: "en-gb",
    timeZone: "UTC",
    events: window.location.pathname + '.json',
    headerToolbar: {
      left: 'prev today',
      center: 'title',
      right: 'next'
      // right: 'dayGridMonth,timeGridWeek,listWeek,resourceTimelineMonth'
    },
    dateClick: function (info) {
      let currentBlockedDay = null;
      window.teamLeavesCalendar.getEvents().every(event => {
        if (event.startStr === info.dateStr) {
          currentBlockedDay = event;
          return false;
        }
        return true;
      });
      if (currentBlockedDay === null) {
        $.ajax({
          type: "POST",
          dataType: "JSON",
          url: window.createUrl,
          data: {
            authenticity_token: $('[name="csrf-token"]')[0].content,
            blocked_day: {
              start_time: info.date.toISOString(),
            }
          },
          success: function (response) {
            window.teamLeavesCalendar.addEvent(response);
          },
          error: function () {
            alert("Unable to block day");
          }
        });
      } else {
        $.ajax({
          type: "DELETE",
          dataType: "JSON",
          url: currentBlockedDay.extendedProps.destroyUrl,
          data: {
            authenticity_token: $('[name="csrf-token"]')[0].content,
          },
          success: function (response) {
            currentBlockedDay.remove()
          },
          error: function (response) {
            alert("Unable to remove blocked day");
          }
        });
      }
    },
  });
  window.teamLeavesCalendar.setOption('height', "100%");
  window.teamLeavesCalendar.render();
  
})
