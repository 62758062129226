import { Calendar } from '@fullcalendar/core';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone'

$(document).on("turbolinks:load", function () {

  const calendarEle = document.getElementById("teamLeavesCalendar");
  if (calendarEle == undefined) return;

  window.teamLeavesCalendar.calendar = new Calendar(calendarEle, {
    plugins: [momentTimezonePlugin, resourceTimelinePlugin, listPlugin, bootstrapPlugin, interactionPlugin],
    schedulerLicenseKey: "0616196517-fcs-1661861808",
    initialView: 'resourceTimelineMonth',
    themeSystem: 'bootstrap',
    height: "auto",
    locale: "en-gb",
    selectable: true,
    timeZone: "Europe/London",
    resourceAreaHeaderContent: "Members",
    validRange: {
      start: window.teamLeavesCalendar.yearStart,
      end: window.teamLeavesCalendar.yearEnd,
    },
    resourceAreaWidth: '15%',
    slotMinWidth: 15,
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next'
    },
    slotDuration: {
      "hours": 12
    },
    slotLabelInterval: {
      "hours": 24
    },
    slotLabelFormat: [
      {
        weekday: 'narrow',
        day: 'numeric'
    }],
    nowIndicator: true,
    resourceOrder: 'title',
    resources: window.teamLeavesCalendar.teamMembers,
    eventSources: [window.location.pathname + '.json', window.teamLeavesCalendar.publicHolidays, window.teamLeavesCalendar.blockedDaysUrl],
    resourceLabelContent: function (arg) {
      return { html: `<a href="${window.teamLeavesCalendar.membershipPath + arg.resource.id}">${arg.resource.title}</a>` };
    },
    eventContent: function (entry) {
      let event = entry.event;
      let icon = event.extendedProps.icon;

      return { html: `<i class="fa ${icon}"></i>` };
    },
    eventDidMount: function (entry) {
      let element = entry.el;
      let event = entry.event;
      let status = event.extendedProps.status ? " - " + event.extendedProps.status : "";
      let title = event.title + status;
      let class_names = event.extendedProps.class_names;

      class_names = class_names.split(" ").filter(n => n);
      for (let index = 0; index < class_names.length; index++) {
        element.classList.add(class_names[index]);
      }
      element.classList.add("text-center");
      // bootstrap tooltip
      $(element).tooltip({
        title: title,
        placement: "right",
        container: "body",
        trigger: "hover"
      });
    },
    eventWillUnmount: function (entry) {
      let element = entry.el;
      $(element).tooltip('dispose');
    },
    eventClick: function (eventClickInfo) {
      $.ajax({
        type: "GET",
        url: eventClickInfo.event.extendedProps.editUrl
      });
    },
    select: function (selectInfo) {
      const canAdd = selectInfo.resource.getEvents().every(event => {
        let end = event.end;
        if (end === null) {
          end = event.start;
          end.setHours(23,59,59,999);
        }
        if ((event.start < selectInfo.end) && (selectInfo.start < end) && (event.extendedProps.class_names.includes("blocked-day"))) return true; // allow blocked days to be overlapped in case manager needs to add a special case leave
        if ((event.start < selectInfo.end) && (selectInfo.start < end)) return false; // if events overlap
        return true;
      });
      if (canAdd) {
        let membership_id = selectInfo.resource.id;
        $.ajax({
          type: "GET",
          url: `/account/hr/memberships/${membership_id}/employee_leaves/new`,
          data: {
            start_time: selectInfo.start.toISOString(),
            end_time: selectInfo.end.toISOString(),
            membership_id: membership_id
          }
        });
      } else {
        window.teamLeavesCalendar.calendar.unselect();
        alert("Date range cannot overlap with existing leaves");
      }
    },
  });

  window.teamLeavesCalendar.calendar.render()
})
