

$(document).on('change', 'input[class=autosubmit][type=checkbox]', function(evt) {
  $(this).closest('form').submit()
  console.log('autosubmit');
});

$(document).on('turbolinks:load', function () {
  $(document).on('blur', 'input[type=text].autosubmit', function(evt) {
    console.log('autosubmit');
    $(this).closest('form').submit()
  });
});
