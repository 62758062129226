import { Alert } from 'bootstrap';
import YearCalendar from 'js-year-calendar';
import 'js-year-calendar/dist/js-year-calendar.css';
import { position } from 'tether';

$(document).on("turbolinks:load", function () {

  if ($('.year-calendar').length > 0) {

    window.employeeLeavesCalendar.calendar = new YearCalendar('.year-calendar', {
      language: 'en',
      style: 'custom',
      alwaysHalfDay: true,
      displayHeader: false,
      enableRangeSelection: true,
      weekStart: 1,
      startDate: window.employeeLeavesCalendar.startDate,
      loadingTemplate: '<div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      customDayRenderer: function (element, currentDate) {
        if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
          element.className += ' weekend';
        }
        let today = new Date();
        if (today.getFullYear() === currentDate.getFullYear() && today.getMonth() === currentDate.getMonth() && today.getDate() === currentDate.getDate()) {
          element.className += ' today';
        }
      },
      customDataSourceRenderer: function (element, date, events) {
        element.className += ' date_'+date.toDateString().replace(/\s/g, '');
        if (events.length > 0) {
          element.className += ' has-events ';
          element.innerHTML = '<span class="date">' + date.getDate() + '</span>';
          for (let i = 0; i < events.length; i++) {
            const event = events[i];
            let class_names = event.class_names;
            if (event.startDate.toDateString() != date.toDateString()) {
              class_names = class_names.replace("starts-at-noon", " ");
            }
            if (event.endDate.toDateString() != date.toDateString()) {
              class_names = class_names.replace("ends-at-noon", " ");
            }
            element.innerHTML = element.innerHTML + '<div class="event '+ class_names+'">' + '<i class="fa ' + event.icon + '">' + '</i></div>';
          }
        }
      },
      // tooltip
      mouseOnDay: function (e) {
        if (e.events.length > 0) {
          var content = [];
          for (var i in e.events) {
            const status = e.events[i].status && e.events[i].type != "UnauthorisedLeave" ? " - " + e.events[i].status : "";
            content.push(e.events[i].name + status);
          }
          $(e.element).tooltip({
            container: 'body',
            html: true,
            title: '<div class="tooltip-title-small">' + content.join(', ') + '</div>',
            placement: 'bottom'
          });
          $(e.element).tooltip('show');
        }
        $('.popover').popover('hide');
      },
      mouseOutDay: function (e) {
        if (e.events.length > 0) {
          $(e.element).tooltip('hide');
        }
      },
      dataSource: async function () {
        let json = await (await fetch(window.employeeLeavesCalendar.jsonEvents)).json();
        let events = json.map((event) => {
          return {
            id: event.id,
            name: event.title,
            startDate: new Date(event.start),
            endDate: new Date(event.end),
            color: event.backgroundColor,
            class_names: event.class_names,
            icon: event.icon,
            editUrl: event.editUrl,
            status: event.status,
            type: event.type,
          };
        });
        let public_holidays = window.employeeLeavesCalendar.publicHolidays.map((event) => {
          return {
            // id: event.id,
            name: event.title,
            startDate: new Date(event.start),
            endDate: new Date(event.start),
            color: "lightgray",
            class_names: "public-holiday",
            icon: event.icon,
          };
        });
        let blocked_days_json = await (await fetch(window.employeeLeavesCalendar.blockedDays)).json();
        let blocked_days = blocked_days_json.map((event) => {
          // console.log(event);
          return {
            // id: event.id,
            name: event.title,
            startDate: new Date(event.start),
            endDate: new Date(event.start),
            color: event.backgroundColor,
            class_names: event.class_names,
            icon: event.icon,
          };
        });
        return events.concat(public_holidays).concat(blocked_days);
      },
      selectRange: function (selectInfo) {
        const hr_manager = window.employeeLeavesCalendar.hr_manager;
        const singleDaySelection = selectInfo.startDate == selectInfo.endDate;
        if (selectInfo.events.filter((event) => event.class_names.includes("public-holiday")).length > 0) {
          alert("You can't create a leave on a public holiday");
        } else if (selectInfo.events.filter((event) => event.class_names.includes("blocked-day")).length > 0 && !hr_manager) {
          alert("You can't create a leave on a blocked day");
        } else if (singleDaySelection && selectInfo.events.length > 1 && selectInfo.startDate == selectInfo.endDate) {
          $(".date_"+ selectInfo.startDate.toDateString().replace(/\s/g, '')).popover({
            placement: 'top',
            html: true,
            trigger: 'focus',
            title: 'Multiple leaves on same day',
            content: selectInfo.events.map((event) => {
              if (event.editUrl) {
                return '<div class="d-flex justify-content-between align-items-center multi-event-row"><div class="type-date-label mr-2">'+event.name+'</div><a href="'+event.editUrl+'" class="remote-link"><i class="picons-thin-icon-thin-0160_arrow_next_right"></i></a></div>';
              } else {
                return '<span>'+event.name+'</span>';
              }
            }).join(''),
          }).popover('show');
        } else if (singleDaySelection && selectInfo.events.length >= 1 && selectInfo.events[0].editUrl) {
          $.ajax({
            type: "GET",
            url: selectInfo.events[0].editUrl
          });
        }
        else {
          let membership_id = window.employeeLeavesCalendar.membershipId;

          let start_date = new Date(selectInfo.startDate);
          let end_date = new Date(selectInfo.endDate);

          start_date.setHours(0, 0, 0);
          end_date.setHours(23, 59, 59);

          $.ajax({
            type: "GET",
            url: window.employeeLeavesCalendar.newLeaveUrl,
            data: {
              start_time: start_date.toISOString(),
              end_time: end_date.toISOString(),
              membership_id: membership_id,
              dateAdjustment: 'none',
            }
          });
        }
      }
    });
  }
});

$(document).on('click', '.remote-link', function(event) {
  console.log("remote-link");
  $('.popover').popover('hide');
  event.preventDefault();
  $.ajax({
    type: "GET",
    url: $(this).attr('href')
  });
});
// $(document).on('focus', ':not(.popover)', function(){
//   $('.popover').popover('hide');
// });
